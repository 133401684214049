export const tags = [
    "All",
    "Node.js",
    "React",
    "Redux",
    "React Native",
    "Express",
    "PostgreSQL",
    "Firebase",
    "Mobile",
    "GraphQL",
    "Gatsby",
];
